import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React from 'react';

const DialogConfirm = ({
  title,
  content,
  cancelText = 'Cancel',
  buttonText = 'Delete',
  isOpen,
  onCancel,
  onConfirm
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="text" onClick={onCancel} color="secondary">
          {cancelText}
        </Button>
        <Button variant="text" onClick={onConfirm} color="primary" autoFocus>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirm;
