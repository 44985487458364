import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { Markdown } from 'components/markdown';
import { createEntityOrder } from 'components/Ordering';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {
  backgroundColor,
  black,
  darkBackgroundColor,
  darkGrey,
  primary,
  white
} from 'shared/theme/colors';
import { useApi } from 'shared/useApi';

import blackWhiteBackground from '../../components/Logo/logo-black-white-background.png';
import useAuth from '../../features/auth/useAuth';
import { Footer } from './components/Footer';
import { Loading } from './components/Loading';
import { NotShared } from './components/NotShared';
import ShowcaseNavigation from './components/ShowcaseNavigation';
import { PageContainer, PageContainerContent } from './Page';
import { ViewLocation } from './ViewLocation';

const Showcase = () => {
  const { showcaseId } = useParams();

  const [showcase, setShowcase] = useState();
  const [showcaseLoaded, setShowcaseLoaded] = useState(false);
  let cumulativeLocationNumber = 0;
  const { isLoaded: authIsLoaded } = useAuth();

  const { fetchShowCase } = useApi();

  useEffect(() => {
    if (!showcaseLoaded && authIsLoaded && showcaseId) {
      fetchShowCase(showcaseId).then((showcase) => {
        setShowcaseLoaded(true);
        setShowcase(showcase);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showcaseLoaded, authIsLoaded, showcaseId]);

  const over800 = useMediaQuery('(min-width:800px)');

  const logoHeight = over800 ? 200 : 140;

  const getGroupHeadingAndLocationBackgroundColor = (increment = true) => {
    const color =
      cumulativeLocationNumber % 2 === 0
        ? backgroundColor
        : darkBackgroundColor;
    if (increment) {
      cumulativeLocationNumber++;
    }
    return color;
  };

  if (!showcaseLoaded) {
    return <Loading />;
  }

  if (!showcase) {
    return <NotShared />;
  }

  return (
    <>
      <Helmet>
        <title>{showcase.title}</title>
      </Helmet>
      <ShowcaseNavigation showcase={showcase} />
      <Box
        id="top"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <PageContainer
          sx={{
            backgroundColor: darkBackgroundColor,
            color: black
          }}
        >
          <PageContainerContent
            sx={{
              alignItems: 'center'
            }}
          >
            <Box height={`${logoHeight}px`} ml={5} mt={-1}>
              <img alt="Logo" src={showcase.logoUrl || blackWhiteBackground} />
            </Box>
            <Typography
              variant="h1"
              color={white}
              my={3}
              fontWeight={700}
              id={`showcase-${showcase.id}`}
              align="center"
            >
              {showcase.title}
            </Typography>
            <Typography
              variant="subtitle1"
              my={3}
              fontSize={'2.5em'}
              fontWeight={500}
            >
              {showcase.subtitle}
            </Typography>
          </PageContainerContent>
        </PageContainer>
        <PageContainer
          sx={{
            background: `linear-gradient(180deg, ${darkBackgroundColor} 0%, ${backgroundColor} 70%)`,
            color: darkGrey,
            flexGrow: 1
          }}
        >
          <PageContainerContent
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1
            }}
          >
            <Markdown variant="showcase">{showcase.info || ''}</Markdown>
          </PageContainerContent>
        </PageContainer>
      </Box>
      {createEntityOrder(showcase.groups)
        .filter((group) => group.sharing === undefined || group.sharing)
        .map((group) => (
          <PageContainer
            key={`group-${group.id}`}
            sx={{
              py: 12,
              backgroundColor: getGroupHeadingAndLocationBackgroundColor(false)
            }}
          >
            <PageContainerContent>
              <Typography variant="h2" fontWeight={700}>
                {group.title}
              </Typography>
            </PageContainerContent>
            <Divider
              sx={{
                borderBottomWidth: 5,
                borderColor: primary,
                marginBottom: 5,
                marginTop: 5
              }}
            />
            <PageContainerContent>
              <Markdown sx={{ marginBottom: 4 }}>{group.info || ''}</Markdown>
            </PageContainerContent>

            {createEntityOrder(group.locations)
              .filter(
                (location) => location.sharing === undefined || location.sharing
              )
              .map((location) => (
                <ViewLocation
                  key={`location-${location.id}`}
                  backgroundColor={getGroupHeadingAndLocationBackgroundColor()}
                  location={location}
                  showcaseId={showcaseId}
                  groupId={group.id}
                />
              ))}
          </PageContainer>
        ))}
      <Footer />
    </>
  );
};

export default Showcase;
