import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { DropzoneField, ProgressWrapper } from 'components';
import { Field, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

const DialogUpload = ({
  title = 'Add files',
  uploadProgress,
  onSubmit,
  onClose,
  isOpen,
  isRequired,
  limit
}) => {
  const initialState = { files: [] };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <ProgressWrapper progress={uploadProgress}>
        <Formik
          initialValues={initialState}
          onSubmit={({ files }) => {
            onSubmit(files);
          }}
          validationSchema={Yup.object().shape({
            files: Yup.mixed()
              .test('fileRequired', 'This field is required.', (value) =>
                isRequired ? value.length : true
              )
              .test(
                'filesLimit',
                `Files upload limit exceeded. Only ${limit} files can be uploaded.`,
                (value) => value.length <= limit
              )
          })}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <Field component={DropzoneField} name="files" margin="normal" />
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Upload
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </ProgressWrapper>
    </Dialog>
  );
};

export default DialogUpload;
