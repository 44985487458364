import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyB2en0zSwci-zPtnICNAoYzX-gnMjYh7sM',
  authDomain: 'fte-showcase.firebaseapp.com',
  projectId: 'fte-showcase',
  storageBucket: 'fte-showcase.appspot.com',
  messagingSenderId: '545017752332',
  appId: '1:545017752332:web:4348590abc60dab1b2f729',
  measurementId: 'G-VG44XBKBHV'
};

firebase.initializeApp(firebaseConfig);

export const fb = firebase;

export const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app, 'europe-west1');
//connectFunctionsEmulator(functions, 'localhost', 5001);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
