import { Typography } from '@mui/material';
import Page from 'components/Page';
import React from 'react';

const Page404 = () => (
  <Page title={'Nothing here'} titleAlign="center">
    <Typography align="center" variant="subtitle2">
      You've reached a place in the application where there is nothing
    </Typography>
  </Page>
);

export default Page404;
