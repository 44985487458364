import FindInPageIcon from '@mui/icons-material/FindInPage';
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { orderBy } from 'lodash';
import React from 'react';

import TableBody from '../TableBody';
import CollapsibleRow from './components/CollapsibleRow';
import EnhancedTableHead from './components/EnhancedTableHead';

const CollapsibleTable = ({
  headCells = [],
  rows = [],
  initLevel,
  disableSort = false
}) => {
  const [sortDirection, setSortDirection] = React.useState('asc');
  const [sortColumnIndex, setSortColumnIndex] = React.useState(0);

  const handleRequestSort = (property) => {
    const isAsc = sortColumnIndex === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortColumnIndex(property);
  };

  const sortedData = !disableSort
    ? orderBy(rows, ({ data }) => data[sortColumnIndex].value, [sortDirection])
    : rows;

  return (
    <TableContainer>
      <Table>
        <EnhancedTableHead
          cells={headCells}
          sortDirection={sortDirection}
          sortColumnIndex={sortColumnIndex}
          onRequestSort={handleRequestSort}
          disableSort={disableSort}
        />
        <TableBody>
          {sortedData.length ? (
            sortedData.map((row, index) => (
              <CollapsibleRow
                {...row}
                level={initLevel}
                sortColumnIndex={sortColumnIndex}
                sortDirection={sortDirection}
                disableSort={disableSort}
                key={index}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={++headCells.length} align="center">
                <FindInPageIcon fontSize="large" />
                <Typography variant="h6">No data</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;
