import { Alert, Box, Link } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export const NotShared = () => (
  <>
    <Helmet>
      <title>Showcaset er ikke delt</title>
    </Helmet>
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh'
      }}
    >
      <Alert severity="info">
        Dette showcaset er ikke delt for øyeblikket. Ta kontakt med{' '}
        <Link href="mailto:post@ftelocations.com">FTE Locations AS</Link> hvis
        du ønsker tilgang.
      </Alert>
    </Box>
  </>
);
