import { useDispatch } from 'react-redux';

import { showSnackbar } from '../store/slice';

const useSnackbar = () => {
  const dispatch = useDispatch();

  return {
    showSuccessSnackbar: (message) => {
      dispatch(showSnackbar({ message }));
    },
    showErrorSnackbar: (message) => {
      dispatch(showSnackbar({ message, variant: 'error' }));
    }
  };
};

export default useSnackbar;
