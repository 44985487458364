import { TextField } from '@mui/material';
import { useTheme } from '@mui/styles';
import AutoSave from 'components/form/AutoSave';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

const Form = ({ group, onSubmit }) => {
  const theme = useTheme();

  const methods = useForm({
    defaultValues: {
      title: group.title ?? '',
      info: group.info ?? ''
    }
  });

  const { control, handleSubmit, reset } = methods;

  useEffect(() => {
    reset({
      title: group.title ?? '',
      info: group.info ?? ''
    });
  }, [group, reset]);

  return (
    <FormProvider {...methods}>
      <AutoSave onSubmit={onSubmit} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={'title'}
          control={control}
          rules={{ required: 'Title is required' }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              required
              variant="standard"
              fullWidth
              placeholder="Group title"
              InputProps={{
                style: {
                  color: theme.palette.primary.main,
                  fontSize: theme.typography.h4.fontSize
                }
              }}
            />
          )}
        />
        <Controller
          name={'info'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant="filled"
              margin="normal"
              placeholder="Info"
              fullWidth
              multiline
              minRows={2}
            />
          )}
        />
      </form>
    </FormProvider>
  );
};

export default Form;
