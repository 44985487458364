import { useTheme } from '@mui/styles';
import MuiMarkdown from 'mui-markdown';
import React from 'react';

import { darkGrey } from '../../shared/theme/colors';

export const Markdown = ({ children, variant = 'info' }) => {
  const theme = useTheme();

  const overrides =
    variant === 'showcase'
      ? {
          p: {
            props: {
              style: { ...theme.typography.subtitle1, fontSize: '1.3em' }
            }
          },
          a: {
            props: {
              style: {
                ...theme.typography.subtitle1,
                color: darkGrey
              }
            }
          }
        }
      : {};

  return <MuiMarkdown overrides={overrides}>{children}</MuiMarkdown>;
};
