import {
  TableHead,
  TableRow,
  TableSortLabel as MuiTableSortLabel
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';

import TableCell from '../../TableCell';

const TableSortLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.action.active
  }
}))(MuiTableSortLabel);

const EnhancedTableHead = (props) => {
  const {
    cells,
    sortDirection,
    sortColumnIndex,
    onRequestSort,
    disableSort = false
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {cells.map((label, index) =>
          disableSort ? (
            <TableCell key={index}>{label}</TableCell>
          ) : (
            <TableCell
              key={index}
              sortDirection={sortColumnIndex === index ? sortDirection : false}
            >
              <TableSortLabel
                active={sortColumnIndex === index}
                direction={sortColumnIndex === index ? sortDirection : 'asc'}
                onClick={() => onRequestSort(index)}
              >
                {label}
              </TableSortLabel>
            </TableCell>
          )
        )}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
