import {
  Button,
  Rating,
  TextField,
  Unstable_Grid2 as Grid
} from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

const CommentForm = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset
  } = useForm({
    defaultValues: {
      name: '',
      role: '',
      comment: '',
      rating: null
    }
  });

  return (
    <form
      onSubmit={handleSubmit((values) => {
        reset();
        return onSubmit(values);
      })}
      noValidate
    >
      <Grid container spacing={1}>
        <Grid xs={12} md={8}>
          <Controller
            name="comment"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                onChange={onChange}
                margin="normal"
                placeholder="Kommentar"
                label="Kommentar"
                fullWidth
                multiline
                minRows={1}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={4} sx={{ alignSelf: 'center' }}>
          <Controller
            name="rating"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Rating
                onChange={(event, value) => {
                  onChange(value);
                }}
                value={value}
                max={5}
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Vennligst fyll inn et navn' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                required
                placeholder="Navn"
                label="Navn"
                fullWidth
                size="small"
              />
            )}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <Controller
            name="role"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                placeholder="Rolle"
                label="Rolle"
                fullWidth
                size="small"
              />
            )}
          />
        </Grid>
        <Grid md={2} sx={{ alignSelf: 'center' }}>
          <Button type="submit" variant="outlined" disabled={!isDirty}>
            Legg til
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CommentForm;
