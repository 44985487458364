import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export const Loading = () => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      minHeight: '100vh'
    }}
  >
    <CircularProgress size={70} thickness={3} color="secondary" />
  </Box>
);
