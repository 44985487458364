import { Box, CircularProgress } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import React from 'react';

const LoaderWrapper = ({ children, isLoading = false, hasError = false }) => {
  return (
    <>
      {!isLoading && !hasError ? (
        children
      ) : (
        <Box textAlign="center" p={4}>
          {isLoading && !hasError ? (
            <CircularProgress size={32} />
          ) : (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Something went wrong
            </Alert>
          )}
        </Box>
      )}
    </>
  );
};

export default LoaderWrapper;
