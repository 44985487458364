import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';
import React from 'react';

import { Ordering } from '../Ordering';
import { Visibility } from '../Visibility';

export const LocationHeading = ({
  onClickDelete,
  onClickVisibility,
  title,
  visible,
  visibleDown,
  visibleUp,
  onClickDown,
  onClickUp
}) => (
  <Box
    display={'flex'}
    alignItems={'center'}
    width={'100%'}
    justifyContent={'space-between'}
  >
    <Box display={'flex'}>
      <Typography>{title}</Typography>
    </Box>
    <Box display={'flex'}>
      <Ordering
        visibleUp={visibleUp}
        onClickUp={onClickUp}
        visibleDown={visibleDown}
        onClickDown={onClickDown}
      />
      <Visibility visible={visible ?? true} onClick={onClickVisibility} />
      <IconButton onClick={onClickDelete}>
        <DeleteIcon />
      </IconButton>
    </Box>
  </Box>
);

LocationHeading.propTypes = {
  title: PropTypes.string,
  onClickDelete: PropTypes.func,
  visible: PropTypes.bool,
  onClickVisibility: PropTypes.func,
  visibleUp: PropTypes.bool,
  onClickUp: PropTypes.func,
  visibleDown: PropTypes.bool,
  onClickDown: PropTypes.func
};
