import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import DialogConfirm from 'components/DialogConfirm';
import * as PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { queryLocations } from 'shared/queries';
import { getShowcaseGroupLocations } from 'shared/selectors';

import useFirebase from '../../services/useFirebase';
import { darkBackgroundColor, white } from '../../shared/theme/colors';
import Location from '../Location';
import { createEntityOrder, Ordering } from '../Ordering';
import { Visibility } from '../Visibility';
import Form from './Form';

const GroupHeading = ({
  onClickDelete,
  title,
  visible,
  onClickVisibility,
  visibleDown,
  visibleUp,
  onClickDown,
  onClickUp
}) => (
  <Box
    display={'flex'}
    alignItems={'center'}
    width={'100%'}
    justifyContent={'space-between'}
  >
    <Box display={'flex'}>
      <HomeIcon sx={{ color: white, marginRight: 2 }} />
      <Typography color={white}>{title}</Typography>
    </Box>
    <Box display={'flex'}>
      <Ordering
        visibleUp={visibleUp}
        onClickUp={onClickUp}
        visibleDown={visibleDown}
        onClickDown={onClickDown}
      />
      <Visibility visible={visible ?? true} onClick={onClickVisibility} />
      <IconButton onClick={onClickDelete}>
        <DeleteIcon />
      </IconButton>
    </Box>
  </Box>
);

GroupHeading.propTypes = {
  title: PropTypes.any,
  onClickDelete: PropTypes.func,
  visible: PropTypes.bool,
  onClickVisibility: PropTypes.func,
  visibleUp: PropTypes.bool,
  onClickUp: PropTypes.func,
  visibleDown: PropTypes.bool,
  onClickDown: PropTypes.func
};

const Group = ({
  showcaseId,
  group,
  visibleDown,
  visibleUp,
  onClickDown,
  onClickUp
}) => {
  const { updateGroup, deleteGroup, addLocation, updateLocation } =
    useFirebase();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useFirestoreConnect([queryLocations(showcaseId, group.id)]);

  const locations = useSelector(
    getShowcaseGroupLocations(showcaseId, group.id)
  );

  const orderedLocations = useMemo(
    () => createEntityOrder(locations),
    [locations]
  );

  const move = async (index, steps) => {
    const copy = [...orderedLocations];
    const entityToMove = copy[index];
    copy.splice(index, 1);
    copy.splice(index + steps, 0, entityToMove);

    const promises = copy.map((location, index) =>
      updateLocation(showcaseId, group.id, location.id, { order: index })
    );

    await Promise.all(promises);
  };

  const onSubmit = async (values) => {
    return await updateGroup(showcaseId, group.id, {
      title: values.title,
      info: values.info
    });
  };

  const toggleVisibility = async () =>
    await updateGroup(showcaseId, group.id, {
      sharing: !group.sharing
    });

  const removeGroup = async () => {
    deleteGroup(showcaseId, group.id);
  };

  const addGroupLocation = async () => {
    addLocation(showcaseId, group.id);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${group.id}-content`}
        id={`${group.id}-header`}
        sx={{ backgroundColor: darkBackgroundColor }}
      >
        <GroupHeading
          title={group.title}
          onClickDelete={(e) => {
            e.stopPropagation();
            setDeleteModalOpen(true);
          }}
          onClickVisibility={async (e) => {
            e.stopPropagation();
            await toggleVisibility();
          }}
          visible={group.sharing}
          visibleUp={visibleUp}
          visibleDown={visibleDown}
          onClickDown={onClickDown}
          onClickUp={onClickUp}
        />
      </AccordionSummary>
      <AccordionDetails>
        <DialogConfirm
          title={`Delete group ${group.title}?`}
          content="All locations will also be deleted"
          buttonText={'Delete'}
          isOpen={deleteModalOpen}
          onCancel={() => setDeleteModalOpen(false)}
          onConfirm={async () => {
            await removeGroup();
            setDeleteModalOpen(false);
          }}
        />
        <Form group={group} onSubmit={onSubmit} />
        <Box>
          {orderedLocations.map((location, index) => (
            <Location
              key={`location-${index}`}
              showcaseId={showcaseId}
              groupId={group.id}
              location={location}
              visibleUp={index > 0}
              visibleDown={index < orderedLocations.length - 1}
              onClickUp={async (e) => {
                e.stopPropagation();
                await move(index, -1);
              }}
              onClickDown={async (e) => {
                e.stopPropagation();
                await move(index, 1);
              }}
            />
          ))}
        </Box>
        <Box display="flex" justifyContent="center">
          <Button color="secondary" aria-label="add" onClick={addGroupLocation}>
            <AddIcon /> Add location
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default Group;
