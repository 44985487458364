import { Box, CircularProgress } from '@mui/material';
import debounce from 'debounce';
import React, { memo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

const AutoSave = memo(({ defaultValues, onSubmit }) => {
  const methods = useFormContext();

  // eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(() => {
      methods.handleSubmit(onSubmit)();
    }, 1000),
    []
  );

  const watchedData = useWatch({
    control: methods.control,
    defaultValue: defaultValues
  });

  useDeepCompareEffect(() => {
    if (methods.formState.isDirty) {
      debouncedSave();
    }
  }, [watchedData]);

  return (
    <Box mt={2} height={20}>
      {methods.formState.isSubmitting && <CircularProgress size={20} />}
    </Box>
  );
});

export default AutoSave;
