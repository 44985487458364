import { LinearProgress } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

const ProgressWrapper = ({ children, progress, hasError = false }) => {
  return (
    <>
      {!progress && !hasError ? (
        children
      ) : (
        <Box textAlign="center" p={4}>
          {progress && !hasError ? (
            progress !== 100 ? (
              <LinearProgressWithLabel value={progress} />
            ) : (
              <CircularProgress size={64} />
            )
          ) : (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Something went wrong
            </Alert>
          )}
        </Box>
      )}
    </>
  );
};

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default ProgressWrapper;
