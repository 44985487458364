import { Box } from '@mui/material';
import React from 'react';

import black from './logo.png';
import blackWhiteBackground from './logo-black-white-background.png';
import white from './logo-white.png';

const Logo = ({ height = 40, variant = 'white' }) => {
  let logo;
  if (variant === 'white') {
    logo = white;
  } else if (variant === 'white-background') {
    logo = blackWhiteBackground;
  } else {
    logo = black;
  }
  return (
    <Box height={`${height}px`} ml={5} mt={-1}>
      <img alt="Logo" src={logo} />
    </Box>
  );
};

export default Logo;
