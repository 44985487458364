import { Add, Delete, Folder } from '@mui/icons-material';
import {
  Avatar,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { fieldToTextField } from 'formik-material-ui';
import React from 'react';
import { useDropzone } from 'react-dropzone';

import { formatFileSize } from './utils';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14
  },
  dropzone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 80,
    outlineWidth: 2,
    outlineStyle: 'dashed',
    outlineColor: 'blue',
    outlineOffset: -2,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  uploadedFiles: {
    marginTop: theme.spacing(2)
  }
}));

const DropzoneField = (props) => {
  const classes = useStyles();

  const {
    error,
    disabled,
    helperText,
    name,
    value: uploadedFiles
  } = fieldToTextField(props);
  const { form, margin } = props;
  const { setFieldValue } = form;

  const onDrop = (acceptedFiles) => {
    name && setFieldValue(name, acceptedFiles);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    acceptedFiles
  } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': []
    }
  });
  const rootProps = getRootProps();

  const onDelete = (key) => {
    uploadedFiles.splice(key, 1);
    name && setFieldValue(name, uploadedFiles);
  };

  const renderFiles = uploadedFiles.map(({ name, size }, index) => (
    <ListItem key={index}>
      <ListItemAvatar>
        <Avatar>
          <Folder />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={name} secondary={size && formatFileSize(size)} />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => onDelete(index)}
          size="large"
        >
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ));

  return (
    <FormControl fullWidth error={!!error} disabled={disabled} margin={margin}>
      <FormLabel className={classes.label} component="legend">
        Drop files here
      </FormLabel>

      <div className={classes.dropzone} {...rootProps}>
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop the files here ...</p> : <Add />}
        {isDragReject ? <p>'This file is not authorized'</p> : null}
      </div>

      <Button
        onClick={(e) => {
          rootProps.ref.current.click();
          e.preventDefault();
        }}
      >
        Or select from computer
      </Button>

      {error && <FormHelperText>{helperText}</FormHelperText>}

      {acceptedFiles.length > 0 && (
        <Paper className={classes.uploadedFiles} variant="outlined">
          <List dense={true}>{renderFiles}</List>
        </Paper>
      )}
    </FormControl>
  );
};

export default DropzoneField;
