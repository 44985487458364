import { auth as firebaseAuth } from 'app/firebase';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';

const useAuth = () => {
  const role = useSelector(
    (state) => get(state, 'firebase.profile.token.claims.role', 'admin') //todo: remove default role mapping
  );
  const auth = useSelector((state) => state.firebase.auth);

  const getIdToken = async () => await firebaseAuth.currentUser.getIdToken();

  const isLoggedIn = isLoaded(auth) && !isEmpty(auth);

  return {
    isAdmin: role === 'admin',
    isLoggedIn: isLoggedIn,
    email: isLoggedIn ? auth.email : null,
    isLoaded: isLoaded(auth),
    getIdToken
  };
};

export default useAuth;
