import { Box, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

import LoaderWrapper from './LoaderWrapper';

const PageContainer = ({
  title,
  actions,
  isLoading = false,
  hasError = false,
  children
}) => (
  <Paper>
    <Box p={3} mt={3}>
      <Box
        display="flex"
        justifyContent="end"
        sx={{
          '& > :not(style)': {
            m: 2
          }
        }}
      >
        {actions}
      </Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        {title && <Typography variant="h2">{title}</Typography>}
      </Box>
      <LoaderWrapper isLoading={isLoading} hasError={hasError}>
        {children}
      </LoaderWrapper>
    </Box>
  </Paper>
);

export default PageContainer;
