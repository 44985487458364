import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, Fab, Grid, Paper } from '@mui/material';
import Page from 'components/Page';
import { format } from 'date-fns';
import useAuth from 'features/auth/useAuth';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';
import { queryShowcases } from 'shared/queries';
import { getShowcases } from 'shared/selectors';

import CollapsibleTable from '../../components/table/CollapsibleTable';
import useFirebase from '../../services/useFirebase';
import { DATE_TIME_FORMAT } from '../../shared/dates';

const Showcases = () => {
  const { isAdmin, email } = useAuth();
  const navigate = useNavigate();
  const { addShowcase } = useFirebase();
  const showcases = useSelector(getShowcases);

  useFirestoreConnect([queryShowcases(isAdmin, email ?? '')]);

  const newShowcase = async () => {
    const id = await addShowcase();
    navigate(`/showcases/${id}`);
  };

  const headCells = ['Title', 'Updated', '', ''];

  function tableRows() {
    return showcases.map((showcase) => ({
      data: [
        { value: showcase.title },
        {
          value: showcase.modifiedAt?.valueOf(),
          formattedValue: format(
            showcase.modifiedAt?.toDate(),
            DATE_TIME_FORMAT
          )
        },
        { value: showcase.sharing ? <LockOpenIcon /> : <LockIcon /> }
      ],
      link: `/showcases/${showcase.id}`
    }));
  }

  return (
    <Page title="Showcases">
      <Paper>
        <Box pr={2} pt={2}>
          <Grid container justifyContent={'flex-end'}>
            <Fab color="primary" aria-label="add" onClick={newShowcase}>
              <AddIcon />
            </Fab>
          </Grid>
        </Box>
        <Box p={3}>
          <CollapsibleTable headCells={headCells} rows={tableRows()} />
        </Box>
      </Paper>
    </Page>
  );
};

export default Showcases;
