import ClearIcon from '@mui/icons-material/Clear';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Card,
  CardActions,
  CardMedia,
  CircularProgress,
  IconButton
} from '@mui/material';
import DialogConfirm from 'components/DialogConfirm';
import React, { useState } from 'react';

import blackWhiteBackground from '../../Logo/logo-black-white-background.png';
import DialogUpload from '../DialogUpload';

const LogoUpload = ({ logoUrl, onUpload, onDelete }) => {
  const [isUploadOpen, setUploadOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const onFileUpload = async (files) => {
    setLoading(true);
    if (files.length > 0) {
      await onUpload(files[0]);
    }
    setLoading(false);
    setUploadOpen(false);
  };

  const logoHeight = 120;

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        image={logoUrl || blackWhiteBackground}
        alt="Logo"
        sx={{
          height: logoHeight,
          width: 'auto',
          margin: '0 auto'
        }}
      />
      <CardActions disableSpacing>
        {isLoading && <CircularProgress color="secondary" />}
        {!isLoading && (
          <IconButton
            title={logoUrl ? 'Replace' : 'Add'}
            aria-label="add"
            onClick={() => setUploadOpen(true)}
          >
            <FileUploadIcon />
          </IconButton>
        )}
        {!isLoading && logoUrl && (
          <IconButton
            title="Remove"
            aria-label="remove"
            onClick={() => setShowDeleteDialog(true)}
          >
            <ClearIcon />
          </IconButton>
        )}
      </CardActions>

      <DialogConfirm
        title="Are you sure you want to delete?"
        buttonText="Delete"
        isOpen={showDeleteDialog}
        onConfirm={() => {
          setShowDeleteDialog(false);
          onDelete();
        }}
        onCancel={() => {
          setShowDeleteDialog(false);
        }}
      />

      <DialogUpload
        title="Add/replace logo"
        isOpen={isUploadOpen}
        onClose={() => setUploadOpen(false)}
        onSubmit={(files) => {
          onFileUpload(files);
        }}
        isRequired={false}
        limit={1}
      />
    </Card>
  );
};

export default LogoUpload;
