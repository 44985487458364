import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, IconButton } from '@mui/material';
import * as PropTypes from 'prop-types';
import React from 'react';

export const Ordering = ({
  visibleUp,
  visibleDown,
  onClickDown,
  onClickUp
}) => (
  <Box display={'flex'}>
    {visibleUp && (
      <IconButton onClick={onClickUp}>
        {' '}
        <ArrowUpwardIcon />
      </IconButton>
    )}
    {visibleDown && (
      <IconButton onClick={onClickDown}>
        {' '}
        <ArrowDownwardIcon />
      </IconButton>
    )}
  </Box>
);

Ordering.propTypes = {
  visibleUp: PropTypes.bool,
  onClickUp: PropTypes.func,
  visibleDown: PropTypes.bool,
  onClickDown: PropTypes.func
};

export const createEntityOrder = (entities) => {
  if (!entities) {
    return [];
  }

  const copy = [...entities];
  copy.sort((a, b) => {
    if (a.order !== undefined && b.order !== undefined) {
      return a.order - b.order;
    } else if (a.order) {
      return -1;
    } else if (b.order) {
      return 1;
    } else {
      //unordered entities last, newest last
      return a.createdAt['seconds'] - b.createdAt['seconds'];
    }
  });
  return copy;
};
