import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Box } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';

const ImageCarousel = ({ images, selectedIndex }) => {
  return (
    <Carousel
      dynamicHeight={true}
      emulateTouch={true}
      infiniteLoop={true}
      selectedItem={selectedIndex}
      showStatus={false}
      showThumbs={false}
      useKeyboardArrows={true}
    >
      {images.map((image) => (
        <Box key={image.id} sx={{ width: '100%' }}>
          <img
            src={image.largeThumbnailUrl}
            alt={image.name}
            style={{
              height: 'auto',
              width: '100%'
            }}
            loading="lazy"
          />
        </Box>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
