import { TableCell as MuiTableCell } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const TableCell = withStyles({
  root: {
    padding: 14,
    paddingRight: 0,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '33px'
  },
  head: {
    fontSize: 16
  }
})(MuiTableCell);

export default TableCell;
