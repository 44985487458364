import {
  groupsPath,
  locationCommentsPath,
  locationImagesPath,
  locationsPath,
  showcasePath,
  showcasesPath
} from './collectionNames';

export const getLocations = (state) => state.firestore.ordered?.locations ?? [];

export const getShowcases = (state) => state.firestore.ordered.showcases ?? [];

export const getShowcase = (id) => (state) =>
  state.firestore.data[showcasesPath()] &&
  state.firestore.data[showcasesPath()][id];

export const getShowcaseGroups = (id) => (state) =>
  state.firestore.ordered[groupsPath(id)] ?? [];

export const getShowcaseGroupLocations = (showcaseId, groupId) => (state) =>
  state.firestore.ordered[locationsPath(showcaseId, groupId)];

export const getShowcaseGroupLocationImages =
  (showcaseId, groupId, locationId) => (state) =>
    state.firestore.ordered[
      locationImagesPath(showcaseId, groupId, locationId)
    ] ?? [];

export const getLocationComments =
  (showcaseId, groupId, locationId) => (state) =>
    state.firestore.ordered[
      locationCommentsPath(showcaseId, groupId, locationId)
    ] ?? [];

// LOADING

export const getShowcaseLoading = (id) => (state) =>
  state.firestore.status.requesting[showcasePath(id)] ?? false;

export const getShowcaseGroupsLoading = (id) => (state) =>
  state.firestore.status.requesting[groupsPath(id)] ?? false;

export const getShowcaseGroupLocationImagesLoading =
  (showcaseId, groupId, locationId) => (state) =>
    state.firestore.status.requesting[
      locationImagesPath(showcaseId, groupId, locationId)
    ] ?? false;

export const getLocationCommentsLoading =
  (showcaseId, groupId, locationId) => (state) =>
    state.firestore.status.requesting[
      locationCommentsPath(showcaseId, groupId, locationId)
    ] ?? false;
