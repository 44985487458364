import { Typography } from '@mui/material';
import Container from '@mui/material/Container';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const Page = ({ children, title, titleAlign, maxWidth = 'lg' }) => {
  return (
    <Container component="main" maxWidth={maxWidth}>
      {title && (
        <>
          <Typography variant="h1" align={titleAlign} gutterBottom>
            {title}
          </Typography>
          <Helmet>
            <title>{title}</title>
          </Helmet>
        </>
      )}
      {children}
    </Container>
  );
};

export default Page;
