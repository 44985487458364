import { Box, Container } from '@mui/material';
import React from 'react';

export const PageContainer = ({ children, sx }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      py: 6,
      ...sx
    }}
  >
    {children}
  </Box>
);
export const PageContainerContent = ({ children, sx }) => (
  <Container
    sx={{
      display: 'flex',
      flexDirection: 'column',
      ...sx
    }}
  >
    {children}
  </Container>
);
