import DragHandleMUIIcon from '@mui/icons-material/DragHandle';
import { IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { forwardRef } from 'react';

const DragHandleIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.grey.A100
  }
}))(DragHandleMUIIcon);

export const DragHandle = forwardRef((props, ref) => {
  return (
    <IconButton color="default" size="large">
      <DragHandleIcon ref={ref} style={{ cursor: 'grab' }} {...props} />
    </IconButton>
  );
});
