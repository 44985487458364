import useAuth from 'features/auth/useAuth';

export const useApi = () => {
  const { isLoggedIn, getIdToken } = useAuth();

  async function getAuthHeader() {
    const headers = new Headers();
    if (isLoggedIn) {
      const idToken = await getIdToken();
      headers.append('Authorization', `Bearer ${idToken}`);
    }
    return headers;
  }

  async function fetchShowCase(id) {
    try {
      const response = await fetch(
        `https://europe-west1-fte-showcase.cloudfunctions.net/showcase?id=${id}`,
        { headers: await getAuthHeader() }
      );

      if (response.status !== 200) {
        return null;
      }
      return response.json();
    } catch (e) {
      return null;
    }
  }

  return {
    fetchShowCase
  };
};
