import MapIcon from '@mui/icons-material/Map';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import { Markdown } from '../../components/markdown';
import useFirebase from '../../services/useFirebase';
import { queryLocationComments } from '../../shared/queries';
import { getLocationComments } from '../../shared/selectors';
import { blue } from '../../shared/theme/colors';
import ModelContainer from '../containers/ModelContainer';
import { Comment } from './components/Comment';
import CommentForm from './components/CommentForm';
import Images from './components/Images';
import { PageContainerContent } from './Page';

export const ViewLocation = ({
  backgroundColor,
  location,
  groupId,
  showcaseId
}) => {
  const { ref, inView } = useInView();

  //todo: can optimize data fetching / use inView?
  useFirestoreConnect([
    queryLocationComments(showcaseId, groupId, location.id)
  ]);

  const comments =
    useSelector(getLocationComments(showcaseId, groupId, location.id)) ?? [];

  const { addLocationComment, deleteLocationComment } = useFirebase();

  return (
    <Box
      sx={{
        background: backgroundColor,
        paddingBottom: 8
      }}
      ref={ref}
    >
      <PageContainerContent>
        <Box sx={{ paddingTop: 8 }} id={`location-${location.id}`}>
          <Typography variant="caption" color={blue}>
            Alternativ
          </Typography>
          <Typography variant="h3" color={blue}>
            {location.title}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: 3
            }}
          >
            {location.images.length > 0 && (
              <Box sx={{ width: '100%' }} my={3}>
                <Images images={location.images} />
              </Box>
            )}
          </Box>
          {location.matterportLink && (
            <>
              <Box sx={{ width: '100%' }} my={3}>
                <ModelContainer
                  title={location.title}
                  url={location.matterportLink}
                  show={inView}
                />
              </Box>
              <Box my={3}>
                <Button
                  startIcon={<ViewInArIcon />}
                  href={location.matterportLink}
                  target={'_blank'}
                >
                  Åpne 3D-modell
                </Button>
              </Box>
            </>
          )}
          <Stack spacing={2}>
            {comments.map((comment) => (
              <Comment
                key={`comment-${comment.id}`}
                comment={comment}
                onDelete={() =>
                  deleteLocationComment(
                    showcaseId,
                    groupId,
                    location.id,
                    comment.id
                  )
                }
              />
            ))}
          </Stack>
          <Box my={3}>
            <CommentForm
              onSubmit={(values) =>
                addLocationComment(showcaseId, groupId, location.id, {
                  ...values
                })
              }
            />
          </Box>
          {location.info && (
            <Paper sx={{ padding: 3 }} elevation={0}>
              <Markdown>{location.info || ''}</Markdown>
            </Paper>
          )}
          {location.mapLink && (
            <Box my={3}>
              <Button
                startIcon={<MapIcon />}
                href={location.mapLink}
                target={'_blank'}
              >
                Åpne kart
              </Button>
            </Box>
          )}
        </Box>
      </PageContainerContent>
    </Box>
  );
};

ViewLocation.propTypes = {
  backgroundColor: PropTypes.string,
  location: PropTypes.object
};
