import { Box, Link, Typography } from '@mui/material';
import React from 'react';

import Logo from '../../../components/Logo';
import { black, lightGrey } from '../../../shared/theme/colors';
import { PageContainer, PageContainerContent } from '../Page';

export const Footer = () => (
  <PageContainer
    sx={{
      backgroundColor: black
    }}
  >
    <PageContainerContent
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <Box>
        <Typography variant="subtitle1" color={lightGrey}>
          Send en mail med dine valg til:
        </Typography>
        <Link
          href="mailto:post@ftelocations.com"
          color={lightGrey}
          variant="subtitle1"
        >
          post@ftelocations.com
        </Link>
      </Box>
      <Logo height={100} />
    </PageContainerContent>
  </PageContainer>
);
