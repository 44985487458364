import { Box, ImageList } from '@mui/material';
import React, { useState } from 'react';

import { SortableContext } from '../shared/sortable/SortableContext';
import { useNumCols } from '../shared/useNumCols';
import DialogConfirm from './DialogConfirm';
import { Image } from './Image';
import ImageModal from './ImageModal';

const ImagesList = ({ images, onDelete, onImagesOrderUpdate }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const numCols = useNumCols();

  return (
    <Box>
      <SortableContext
        items={images.filter((image) => !!image).map((image) => image.id)}
        updateItemOrder={onImagesOrderUpdate}
      >
        <ImageList cols={numCols}>
          {images
            .filter((image) => !!image)
            .map((image) => (
              <Image
                key={image.id}
                image={image}
                setSelectedFile={setSelectedFile}
                setModalOpen={setModalOpen}
                setFileToDelete={setFileToDelete}
              />
            ))}

          {onDelete && (
            <DialogConfirm
              title={'Are you sure you want to delete?'}
              buttonText={'Delete'}
              isOpen={!!fileToDelete}
              onCancel={() => setFileToDelete(null)}
              onConfirm={async () => {
                if (!!fileToDelete) {
                  onDelete(fileToDelete);
                }
                setFileToDelete(null);
              }}
            />
          )}

          {selectedFile && (
            <ImageModal
              title={selectedFile.name}
              open={isModalOpen}
              handleClose={() => setModalOpen(false)}
            >
              <img
                src={selectedFile.largeThumbnailUrl}
                alt={selectedFile.name}
              />
            </ImageModal>
          )}
        </ImageList>
      </SortableContext>
    </Box>
  );
};

export default ImagesList;
