import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext as DndKitSortableContext
} from '@dnd-kit/sortable';
import React, { useCallback } from 'react';

export function SortableContext({ children, items, updateItemOrder }) {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        const newOrder = arrayMove(items, oldIndex, newIndex);

        updateItemOrder(newOrder);
      }
    },
    [items, updateItemOrder]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <DndKitSortableContext items={items} strategy={rectSortingStrategy}>
        {children}
      </DndKitSortableContext>
    </DndContext>
  );
}
