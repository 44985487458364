import { LinearProgress } from '@mui/material';
import Layout from 'components/Layout';
import { Login, Showcase, Showcases, ViewShowcase } from 'pages';
import Page404 from 'pages/Page404/Page404';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';

import { FirebaseAnalytics } from './analytics';

const PrivateRoute = ({ children, ...props }) => {
  const auth = useSelector((state) => state.firebase.auth);
  const location = useLocation();
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isLoaded(auth) && !isEmpty(auth) ? (
    <Outlet />
  ) : (
    <Navigate to={'/login'} state={{ from: location }} />
  );
};

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <LinearProgress />;
  return <>{children}</>;
};

const AppRouter = () => {
  return (
    <Router>
      <HelmetProvider>
        <AuthIsLoaded>
          <FirebaseAnalytics />
          <Routes>
            <Route exact element={<PrivateRoute />}>
              <Route
                exact
                path="/"
                element={
                  <Layout hasMenu={true}>
                    <Showcases />
                  </Layout>
                }
              ></Route>
              <Route
                exact
                path="/showcases"
                element={
                  <Layout hasMenu={true}>
                    <Showcases />
                  </Layout>
                }
              ></Route>
              <Route
                path="/showcases/:showcaseId"
                element={
                  <Layout hasMenu={true}>
                    <Showcase />
                  </Layout>
                }
              ></Route>
            </Route>
            <Route path="/view/:showcaseId" element={<ViewShowcase />}></Route>
            <Route
              path="/login"
              element={
                <Layout>
                  <Login />
                </Layout>
              }
            ></Route>
            <Route
              element={
                <Layout>
                  <Page404 />
                </Layout>
              }
            ></Route>
          </Routes>
        </AuthIsLoaded>
      </HelmetProvider>
    </Router>
  );
};

export default AppRouter;
