import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, Tooltip } from '@mui/material';
import * as PropTypes from 'prop-types';
import React from 'react';

export const Visibility = ({ visible, onClick }) => (
  <Tooltip
    title={
      visible ? 'Click to hide from showcase' : 'Click to include in showcase'
    }
  >
    <IconButton onClick={onClick}>
      {visible && <VisibilityIcon />}
      {!visible && <VisibilityOffIcon />}
    </IconButton>
  </Tooltip>
);

Visibility.propTypes = {
  visible: PropTypes.bool,
  onClick: PropTypes.func
};
