import { ref } from 'firebase/storage';

import { auth, storage } from '../app/firebase';
import {
  showcaseGroupLocationImageStoragePath,
  showcaseGroupLocationLargeThumbnailStoragePath,
  showcaseGroupLocationSmallThumbnailStoragePath,
  showcaseLogoStoragePath
} from '../shared/collectionNames';

export const getCurrentUserEmail = () => auth.currentUser?.email ?? null;

const storageImageRef = (path) => ref(storage, path);

const storageRefFromUrl = (url) => ref(storage, url);

export const getShowcaseLogoStorageRefFromUrl = (url) => storageRefFromUrl(url);

export const getShowcaseLogoStorageRef = (showcaseId, fileName) =>
  storageImageRef(showcaseLogoStoragePath(showcaseId, fileName));

export const getLocationImageStorageRef = (locationId, fileName) =>
  storageImageRef(showcaseGroupLocationImageStoragePath(locationId, fileName));

export const getLocationImageSmallThumbStorageRef = (locationId, fileName) =>
  storageImageRef(
    showcaseGroupLocationSmallThumbnailStoragePath(locationId, fileName)
  );

export const getLocationImageLargeThumbStorageRef = (locationId, fileName) =>
  storageImageRef(
    showcaseGroupLocationLargeThumbnailStoragePath(locationId, fileName)
  );
