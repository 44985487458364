import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Close from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  ImageListItem,
  ImageListItemBar
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';

import { DragHandle } from '../shared/sortable/DragHandle';

const CloseIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.grey.A100
  }
}))(Close);
export const Image = ({
  image,
  setSelectedFile,
  setModalOpen,
  setFileToDelete
}) => {
  const { smallThumbnailUrl, largeThumbnailUrl, name, id } = image;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <ImageListItem>
        <Box
          onClick={() => {
            setSelectedFile({ name, largeThumbnailUrl });
            setModalOpen(true);
          }}
        >
          <img
            src={smallThumbnailUrl}
            alt={name}
            style={{ height: 'auto', width: '100%' }}
            loading="lazy"
          />
        </Box>
        <ImageListItemBar
          actionIcon={
            <>
              <DragHandle {...listeners} />
              <IconButton
                color="default"
                onClick={(event) => {
                  event.preventDefault();
                  setFileToDelete(image);
                }}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </>
          }
        />
      </ImageListItem>
    </div>
  );
};
