import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import Page from 'components/Page';
import useSnackbar from 'features/snackbar/hooks/useSnackbar';
import { Field, FormikProvider, useFormik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  }
}));

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const firebase = useFirebase();
  const { showErrorSnackbar } = useSnackbar();

  const onSubmit = async (values, actions) => {
    try {
      await firebase.login({
        email: values.email,
        password: values.pass
      });
      navigate('/');
    } catch (e) {
      switch (e?.code) {
        case 'auth/user-not-found':
          showErrorSnackbar(
            'There is no user record corresponding to this identifier.'
          );
          break;
        case 'auth/wrong-passwords':
          showErrorSnackbar('The password is invalid.');
          break;
        default:
          showErrorSnackbar(e?.message ?? 'Something went wrong');
      }
    }
    actions.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: { email: '', pass: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required('This field is required.'),
      pass: Yup.string().required('This field is required.')
    }),
    onSubmit
  });

  return (
    <Page title="Sign in" maxWidth="xs" titleAlign="center">
      <Box display="flex" flexDirection="column" alignItems="center" mt={6}>
        <Avatar>
          <LockOutlinedIcon />
        </Avatar>
        <FormikProvider value={formik}>
          <form
            onSubmit={formik.handleSubmit}
            className={classes.form}
            noValidate
          >
            <Field
              component={TextField}
              variant="outlined"
              margin="normal"
              name="email"
              label="Email Address"
              type="email"
              autoFocus
              fullWidth
              required
              error={!!formik.touched.email && !!formik.errors.email}
              helperText={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : null
              }
            />
            <Field
              component={TextField}
              type="password"
              variant="outlined"
              margin="normal"
              name="pass"
              label="Password"
              fullWidth
              required
              error={!!formik.touched.pass && !!formik.errors.pass}
              helperText={
                formik.errors.pass && formik.touched.pass
                  ? formik.errors.pass
                  : null
              }
            />
            <Box my={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
              >
                {formik.isSubmitting ? (
                  <CircularProgress size={20} />
                ) : (
                  'Sign In'
                )}
              </Button>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </Page>
  );
};

export default Login;
