import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useState } from 'react';

import Header from './components/Header';
import Sidebar from './components/Sidebar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  wrapperShift: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const Layout = ({ children, hasMenu = false }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <Header
        hasMenu={hasMenu}
        onMobileNavOpen={() => setMobileNavOpen(true)}
      />
      {hasMenu && (
        <Sidebar
          onMobileClose={() => setMobileNavOpen(false)}
          isMobileNavOpen={isMobileNavOpen}
        />
      )}
      <div
        className={clsx(classes.wrapper, hasMenu ? classes.wrapperShift : null)}
      >
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
