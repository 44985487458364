import { TableBody as MuiTableBody } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const TableBody = withStyles((theme) => ({
  root: {
    outline: '1px solid;',
    outlineColor: theme.palette.divider,
    outlineOffset: -1
  }
}))(MuiTableBody);

export default TableBody;
