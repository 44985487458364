import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';

import { darkBackgroundColor } from '../../shared/theme/colors';

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const heightMap = {
  xs: 250,
  sm: 400,
  md: 550,
  lg: 650,
  xl: 700
};

const ModelContainer = ({ url, title, show }) => {
  const width = useWidth();

  if (!show) {
    return (
      <Box
        sx={{
          width: '100%',
          height: `${heightMap[width] || 450}px`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px dotted ${darkBackgroundColor}`
        }}
      >
        <Button startIcon={<ViewInArIcon />} href={url} target={'_blank'}>
          Åpne 3D-modell
        </Button>
      </Box>
    );
  }

  return (
    <iframe
      width="100%"
      height={`${heightMap[width] || 450}px`}
      src={url}
      title={title}
      frameBorder="0"
      loading="lazy"
      allowFullScreen
      allow="xr-spatial-tracking"
    />
  );
};

export default ModelContainer;
