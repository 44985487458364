import useAuth from 'features/auth/useAuth';
import useSnackbar from 'features/snackbar/hooks/useSnackbar';
import React from 'react';
import { useFirebase } from 'react-redux-firebase';

import Dropdown from './Dropdown';

const ProfileDropdown = () => {
  const { email } = useAuth();
  const firebase = useFirebase();
  const { showErrorSnackbar } = useSnackbar();

  const onLogout = async () => {
    try {
      await firebase.logout();
    } catch (e) {
      showErrorSnackbar('Something went wrong');
    }
  };

  const options = [{ name: 'Logout', onClick: onLogout }];

  return <Dropdown text={email ?? ''} options={options} />;
};

export default ProfileDropdown;
