import { configureStore } from '@reduxjs/toolkit';

import { rtkQueryErrorLogger } from './apiErrorHandlingMiddleware';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(rtkQueryErrorLogger)
});

export default store;
