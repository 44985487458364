import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Collapse,
  Divider,
  List,
  ListItem,
  MenuItem,
  Toolbar
} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { createEntityOrder } from 'components/Ordering';
import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll/lib/anchor-link';
import { orange } from 'shared/theme/colors';

const GroupItem = ({ group, onClick }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={group.title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {createEntityOrder(group.locations)
            .filter(
              (location) => location.sharing === undefined || location.sharing
            )
            .map((location) => (
              <AnchorLink
                key={`location_menu_item-${location.id}`}
                href={`#location-${location.id}`}
                style={{ color: theme.palette.text.primary }}
                onClick={onClick}
              >
                <MenuItem>{location.title}</MenuItem>
              </AnchorLink>
            ))}
        </List>
      </Collapse>
      <Divider />
    </>
  );
};

export default function ShowcaseNavigation({ showcase }) {
  const [open, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(open);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        backgroundColor: 'transparent',
        zIndex: 'appBar'
      }}
    >
      <AppBar color={'transparent'} elevation={0}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            sx={{
              backgroundColor: 'rgba(1, 1, 1, .4)',
              '&:hover': {
                backgroundColor: 'rgba(1, 1, 1, .05)'
              }
            }}
          >
            <MenuIcon sx={{ color: orange, fontSize: 40 }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar>{/*Just filling up the height of the fixed toolbar*/}</Toolbar>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            mt: 3,
            p: 5,
            height: 1
          }}
        >
          <AnchorLink href="#top" onClick={toggleDrawer(false)}>
            <Typography variant="h4" gutterBottom component="div">
              {showcase.title}
            </Typography>
          </AnchorLink>

          <Box sx={{ my: 2 }}>
            {createEntityOrder(showcase.groups)
              .filter((group) => group.sharing === undefined || group.sharing)
              .map((group) => (
                <GroupItem
                  key={`group_edit-${group.id}`}
                  group={{
                    title: group.title,
                    locations: group.locations
                  }}
                  onClick={toggleDrawer(false)}
                />
              ))}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
