import { Box, ImageList, ImageListItem } from '@mui/material';
import React, { useState } from 'react';

import { useNumCols } from '../../../shared/useNumCols';
import ImageCarousel from './ImageCarousel';

const Images = ({ images }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const numColsInImageList = useNumCols();

  return (
    <>
      <Box my={2}>
        <ImageCarousel images={images} selectedIndex={selectedIndex} />
      </Box>
      <Box>
        {images?.length > 0 && (
          <ImageList cols={numColsInImageList}>
            {images?.map((image, index) => {
              const { smallThumbnailUrl, name } = image;
              return (
                <ImageListItem
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setSelectedIndex(index)}
                >
                  <Box>
                    <img
                      src={smallThumbnailUrl}
                      alt={name}
                      style={{
                        width: '100%'
                      }}
                      loading="lazy"
                    />
                  </Box>
                </ImageListItem>
              );
            })}
          </ImageList>
        )}
      </Box>
    </>
  );
};

export default Images;
