import { Button, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  }
}));

const SidebarItem = ({ href, title }) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.item} disableGutters>
      <Button className={classes.button} component={RouterLink} to={href}>
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
};

export default SidebarItem;
