import { httpsCallable } from 'firebase/functions';
import { deleteObject, getDownloadURL, uploadBytes } from 'firebase/storage';
import { useFirestore } from 'react-redux-firebase';

import { functions } from '../app/firebase';
import {
  groupsPath,
  locationCommentPath,
  locationCommentsPath,
  locationImagePath,
  locationImagesPath,
  locationsPath,
  showcaseGroupLocationPath,
  showcaseGroupPath,
  showcasePath,
  showcasesPath
} from '../shared/collectionNames';
import {
  getCurrentUserEmail,
  getLocationImageLargeThumbStorageRef,
  getLocationImageSmallThumbStorageRef,
  getLocationImageStorageRef,
  getShowcaseLogoStorageRef,
  getShowcaseLogoStorageRefFromUrl
} from './firestoreService';

const created = () => ({
  createdAt: new Date(),
  createdBy: getCurrentUserEmail() ?? 'unauthenticated'
});

const modified = () => ({
  modifiedAt: new Date(),
  modifiedBy: getCurrentUserEmail() ?? 'unauthenticated'
});

export default function useFirebase() {
  const firestore = useFirestore();

  // GET
  const getShowcase = async (id) => {
    const getShowcase = httpsCallable(functions, 'showcase');
    return await getShowcase({ id }).catch(function (err) {
      console.warn(err);
    });
  };

  // ADD
  const addShowcase = async () => {
    return firestore
      .add(showcasesPath(), {
        sharing: false,
        ...created(),
        ...modified()
      })
      .then((documentReference) => documentReference.id);
  };

  const addGroup = async (showcaseId) => {
    return firestore.add(groupsPath(showcaseId), {
      sharing: true,
      ...created(),
      ...modified()
    });
  };

  const addLocation = async (showcaseId, groupId) => {
    return firestore.add(locationsPath(showcaseId, groupId), {
      sharing: true,
      ...created(),
      ...modified()
    });
  };

  const addLocationComment = async (
    showcaseId,
    groupId,
    locationId,
    { name, role, comment, rating }
  ) => {
    return firestore.add(
      locationCommentsPath(showcaseId, groupId, locationId),
      {
        name,
        role,
        comment,
        rating,
        ...created(),
        ...modified()
      }
    );
  };

  // UPDATE
  const updateShowcase = async (showcaseId, showcase) => {
    return firestore.update(showcasePath(showcaseId), {
      ...showcase,
      ...modified()
    });
  };

  const updateGroup = async (showcaseId, groupId, group) => {
    return firestore.update(showcaseGroupPath(showcaseId, groupId), {
      ...group,
      ...modified()
    });
  };

  const updateLocation = async (showcaseId, groupId, locationId, location) => {
    return firestore.update(
      showcaseGroupLocationPath(showcaseId, groupId, locationId),
      {
        ...location,
        ...modified()
      }
    );
  };

  // DELETE

  /**
   * Call the 'recursiveDelete' callable function with a path to initiate
   * a server-side delete.
   */
  const deleteAtPath = async (path) => {
    const recursiveDelete = httpsCallable(functions, 'recursiveDelete');
    await recursiveDelete({ path: path }).catch(function (err) {
      console.warn(err);
    });
  };

  const deleteShowcase = async (showcaseId) => {
    await deleteAtPath(showcasePath(showcaseId));
  };

  const deleteGroup = async (showcaseId, groupId) => {
    await deleteAtPath(showcaseGroupPath(showcaseId, groupId));
  };

  const deleteLocation = async (showcaseId, groupId, locationId) => {
    await deleteAtPath(
      showcaseGroupLocationPath(showcaseId, groupId, locationId)
    );
  };

  /**
   * Don't use function for this one. Function requires auth, but comments can be deleted by anyone.
   */
  const deleteLocationComment = async (
    showcaseId,
    groupId,
    locationId,
    commentId
  ) => {
    await firestore.delete(
      locationCommentPath(showcaseId, groupId, locationId, commentId)
    );
  };

  const deleteLocationImage = async (
    showcaseId,
    groupId,
    locationId,
    image
  ) => {
    await deleteLocationImageBinaries(locationId, image);
    await deleteAtPath(
      locationImagePath(showcaseId, groupId, locationId, image.id)
    );
  };

  async function deleteLocationImageBinaries(locationId, image) {
    try {
      await deleteObject(
        getLocationImageStorageRef(locationId, image.storageName)
      );
    } catch (e) {
      console.error('Could not delete file, it might not exist', image, e);
    }
    try {
      await deleteObject(
        getLocationImageSmallThumbStorageRef(locationId, image.storageName)
      );
    } catch (e) {
      console.error(
        'Could not delete small thumb, it might not exist',
        image,
        e
      );
    }
    try {
      await deleteObject(
        getLocationImageLargeThumbStorageRef(locationId, image.storageName)
      );
    } catch (e) {
      console.error(
        'Could not delete small thumb, it might not exist',
        image,
        e
      );
    }
  }

  const getLocationImagesRef = async (showcaseId, groupId, locationId) => {
    return firestore
      .collection(locationImagesPath(showcaseId, groupId, locationId))
      .doc();
  };

  // upload

  const uploadImages = async (
    showcaseId,
    groupId,
    locationId,
    files,
    onProgressUpdate
  ) => {
    let filesFinishedUploading = 0;
    return Promise.all(
      files.map(async (file) => {
        const databaseRef = await getLocationImagesRef(
          showcaseId,
          groupId,
          locationId
        );

        const storageName = `${databaseRef.id}-${file.name}`;
        const storageRef = getLocationImageStorageRef(locationId, storageName);

        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);

        const image = {
          id: databaseRef.id,
          name: file.name,
          storageName,
          size: file.size,
          contentType: file.type,
          lastModified: file.lastModified,
          url,
          ...created(),
          ...modified()
        };

        await databaseRef.set(image);

        filesFinishedUploading += 1;
        onProgressUpdate((100 / files.length) * filesFinishedUploading);
      })
    );
  };

  const uploadLogo = async (showcaseId, file) => {
    const storageName = `${showcaseId}-${file.name}`;
    const storageRef = getShowcaseLogoStorageRef(showcaseId, storageName);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const deleteLogo = async (logoUrl) => {
    try {
      await deleteObject(getShowcaseLogoStorageRefFromUrl(logoUrl));
    } catch (e) {
      console.error('Could not delete file, it might not exist', logoUrl, e);
    }
  };

  return {
    getShowcase,
    addShowcase,
    addGroup,
    addLocation,
    addLocationComment,
    updateShowcase,
    updateGroup,
    updateLocation,
    deleteShowcase,
    deleteGroup,
    deleteLocation,
    deleteImage: deleteLocationImage,
    deleteLocationComment,
    uploadImages,
    uploadLogo,
    deleteLogo
  };
}
