import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import theme from 'shared/theme';
import GlobalStyles from 'shared/theme/GlobalStyles';

import Notifier from '../features/snackbar/Notifier';
import AppRouter from './AppRouter';
import ErrorBoundary from './ErrorBoundary';
import { fb } from './firebase';
import store from './store';

const reactReduxFirebaseProps = {
  firebase: fb,
  config: { enableClaims: true },
  dispatch: store.dispatch,
  createFirestoreInstance
};

function App() {
  return (
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <Provider store={store}>
            <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
              >
                <AppRouter />
                <Notifier />
              </SnackbarProvider>
            </ReactReduxFirebaseProvider>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
}

export default App;
