import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeSnackbar, setDisplayedSnackbar } from './store/slice';

const Notifier = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    notifications.forEach(
      ({
        key,
        message,
        variant,
        onMoreInfo,
        onRetry,
        dismissed = false,
        displayed = false
      }) => {
        if (dismissed) {
          closeSnackbar(key);
          return;
        }

        if (displayed) return;

        const action = (key) => (
          <>
            {onMoreInfo ? (
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  onMoreInfo();
                  closeSnackbar(key);
                }}
              >
                More info
              </Button>
            ) : null}
            {onRetry ? (
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  onRetry();
                  closeSnackbar(key);
                }}
              >
                RETRY
              </Button>
            ) : null}
          </>
        );

        enqueueSnackbar(message, {
          key,
          action,
          variant,
          onExited: (event, myKey) => {
            const key = Number(myKey);
            dispatch(removeSnackbar(key));
          }
        });

        dispatch(setDisplayedSnackbar(key));
      }
    );
  }, [closeSnackbar, enqueueSnackbar, notifications, dispatch]);

  return null;
};

export default Notifier;
