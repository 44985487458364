import { getAnalytics, logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function FirebaseAnalytics() {
  const location = useLocation();
  useEffect(() => {
    const analytics = getAnalytics();
    const page_path = location.pathname + location.search;
    logEvent(analytics, 'screen_view', {
      firebase_screen: page_path
    });
  }, [location]);
  return null;
}
