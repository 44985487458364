import { createTheme, responsiveFontSizes } from '@mui/material';

import * as colors from './colors';

let theme = createTheme({
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    }
  },
  shape: {
    borderRadius: 2
  },
  typography: {
    h1: {
      color: colors.primary
    },
    h2: {
      color: colors.primary
    },
    h3: {
      color: colors.headingColor
    },
    h4: {
      color: colors.headingColor
    },
    h5: {
      color: colors.headingColor
    },
    h6: {
      color: colors.headingColor
    }
  }
});

theme = responsiveFontSizes(theme);

window.theme = theme;

export default theme;
