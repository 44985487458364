import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
export const initialState = {
  notifications: []
};

const snackbar = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showSnackbar(state, { payload: notification }) {
      state.notifications.push({
        variant: 'success',
        ...notification,
        key: new Date().getTime() + Math.random()
      });
    },
    setDisplayedSnackbar: (state, { payload: key }) => {
      const notificationIndex = findIndex(state.notifications, { key });
      if (notificationIndex !== -1) {
        state.notifications[notificationIndex].displayed = true;
      }
    },
    removeSnackbar: (state, { payload: key }) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== key
      );
    }
  }
});

export const {
  showSnackbar,
  removeSnackbar,
  setDisplayedSnackbar
} = snackbar.actions;

export default snackbar.reducer;
