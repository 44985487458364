import LunchDiningIcon from '@mui/icons-material/LunchDining';
import { AppBar, Box, Hidden, IconButton, Toolbar } from '@mui/material';
import Logo from 'components/Logo';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ProfileDropdown from './components/ProfileDropdown';

const Header = ({ hasMenu = false, onMobileNavOpen }) => (
  <AppBar elevation={0}>
    <Toolbar>
      <RouterLink to="/">
        <Logo />
      </RouterLink>
      {hasMenu && (
        <>
          <Box flexGrow={1} />
          <Hidden mdDown>
            <Box mx={1} />
            <ProfileDropdown />
          </Hidden>
          <Hidden mdUp>
            <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
              <LunchDiningIcon />
            </IconButton>
          </Hidden>
        </>
      )}
    </Toolbar>
  </AppBar>
);

export default Header;
