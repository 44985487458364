import {
  groupsPath,
  locationCommentsPath,
  locationImagesPath,
  locationsPath,
  showcaseCollectionName
} from './collectionNames';

export const queryShowcase = (id) => ({
  collection: showcaseCollectionName,
  doc: id
});

export const queryGroups = (id) => ({
  collection: groupsPath(id),
  orderBy: ['createdAt', 'asc']
});

export const queryLocations = (showcaseId, groupId) => ({
  collection: locationsPath(showcaseId, groupId),
  orderBy: ['createdAt', 'asc']
});

export const queryLocationImages = (showcaseId, groupId, locationId) => ({
  collection: locationImagesPath(showcaseId, groupId, locationId),
  orderBy: ['createdAt', 'asc']
});

export const queryLocationComments = (showcaseId, groupId, locationId) => ({
  collection: locationCommentsPath(showcaseId, groupId, locationId),
  orderBy: ['createdAt', 'asc']
});

export const queryShowcases = (isAdmin, email) => ({
  collection: showcaseCollectionName,
  where: !isAdmin ? [['users', 'array-contains', email]] : undefined
});
