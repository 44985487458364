import { Delete } from '@mui/icons-material';
import { Button, Card, Rating, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';

import DialogConfirm from '../../../components/DialogConfirm';
import { DATE_TIME_FORMAT } from '../../../shared/dates';
import { darkGrey, grey, orange } from '../../../shared/theme/colors';

export const Comment = ({ comment, onDelete }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <Card sx={{ padding: 2 }}>
      <DialogConfirm
        title={`Vil du slette kommentaren?`}
        cancelText={'Avbryt'}
        buttonText={'Slett'}
        isOpen={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={async () => {
          await onDelete();
          setDeleteModalOpen(false);
        }}
      />
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack spacing={2} direction="row" alignItems="center">
          <Rating value={comment.rating} max={5} readOnly />
          <Typography fontWeight="bold" sx={{ color: { darkGrey } }}>
            {comment.name}
            {comment.role && <span>, {comment.role}</span>}
          </Typography>
          <Typography sx={{ color: { grey } }}>
            {format(comment.modifiedAt?.toDate(), DATE_TIME_FORMAT)}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            startIcon={<Delete />}
            sx={{
              color: { orange },
              fontWeight: 500,
              textTransform: 'capitalize'
            }}
            onClick={() => {
              setDeleteModalOpen(true);
            }}
          >
            Slett
          </Button>
        </Stack>
      </Stack>
      <Typography sx={{ color: { grey }, p: '20px 0' }}>
        {comment.comment}
      </Typography>
    </Card>
  );
};
