import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import DialogConfirm from 'components/DialogConfirm';
import ImagesContainer from 'pages/containers/ImagesContainer';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import useFirebase from 'services/useFirebase';
import { queryLocationImages } from 'shared/queries';
import {
  getShowcaseGroupLocationImages,
  getShowcaseGroupLocationImagesLoading
} from 'shared/selectors';

import { backgroundColor } from '../../shared/theme/colors';
import Form from './Form';
import { LocationHeading } from './LocationHeading';

const Location = ({
  showcaseId,
  groupId,
  location,
  visibleDown,
  visibleUp,
  onClickDown,
  onClickUp
}) => {
  const { updateLocation, deleteLocation, deleteImage, uploadImages } =
    useFirebase();
  const [uploadProgress, setUploadProgress] = useState();

  useFirestoreConnect([queryLocationImages(showcaseId, groupId, location.id)]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const images = useSelector(
    getShowcaseGroupLocationImages(showcaseId, groupId, location.id)
  );

  const loading = useSelector(
    getShowcaseGroupLocationImagesLoading(showcaseId, groupId, location.id)
  );

  const [sortedImages, setSortedImages] = useState([]);

  useEffect(() => {
    function getImagesOrder() {
      if (!location.imagesOrder) {
        let imagesOrder = images.map((image) => image.id);
        updateImagesOrder(imagesOrder);
        return imagesOrder;
      }

      let imagesOrder = [...location.imagesOrder];

      const newImageIds = images
        .filter((image) => !imagesOrder.includes(image.id))
        .map((image) => image.id);

      imagesOrder.push(...newImageIds);

      const removedImageIds = imagesOrder.filter(
        (id) => !images.find((image) => image.id === id)
      );

      imagesOrder = imagesOrder.filter((id) => !removedImageIds.includes(id));

      if (newImageIds.length || removedImageIds.length) {
        updateImagesOrder(imagesOrder);
      }

      return imagesOrder;
    }

    if (location && images.length) {
      const imagesOrder = getImagesOrder();
      setSortedImages(
        imagesOrder.map((id) => images.find((image) => image.id === id))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, images]);

  const updateImagesOrder = async (imagesOrder) => {
    setSortedImages(
      imagesOrder.map((id) => images.find((image) => image.id === id))
    );
    return await updateLocation(showcaseId, groupId, location.id, {
      imagesOrder
    });
  };

  const onSubmit = async (values) =>
    await updateLocation(showcaseId, groupId, location.id, {
      id: location.id,
      title: values.title,
      info: values.info,
      matterportLink: values.matterportLink,
      mapLink: values.mapLink
    });

  const toggleVisibility = async () =>
    await updateLocation(showcaseId, groupId, location.id, {
      sharing: !location.sharing
    });

  const removeLocation = async () => {
    deleteLocation(showcaseId, groupId, location.id);
  };

  const removeImage = async (image) => {
    deleteImage(showcaseId, groupId, location.id, image);
  };

  const upload = async (images) => {
    setUploadProgress(1);
    await uploadImages(showcaseId, groupId, location.id, images, (progress) =>
      setUploadProgress(progress)
    );
    setUploadProgress(undefined);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${location.id}-content`}
        id={`${location.id}-header`}
        sx={{ backgroundColor: backgroundColor }}
      >
        <LocationHeading
          title={location.title}
          onClickDelete={(e) => {
            e.stopPropagation();
            setDeleteModalOpen(true);
          }}
          onClickVisibility={async (e) => {
            e.stopPropagation();
            await toggleVisibility();
          }}
          visible={location.sharing}
          visibleUp={visibleUp}
          visibleDown={visibleDown}
          onClickDown={onClickDown}
          onClickUp={onClickUp}
        />
      </AccordionSummary>
      <AccordionDetails>
        <DialogConfirm
          title={`Delete location ${location.title}?`}
          buttonText={'Delete'}
          isOpen={deleteModalOpen}
          onCancel={() => setDeleteModalOpen(false)}
          onConfirm={async () => {
            await removeLocation();
            setDeleteModalOpen(false);
          }}
        />
        <Form location={location} onSubmit={onSubmit} />
        <ImagesContainer
          images={sortedImages}
          isLoading={loading}
          uploadProgress={uploadProgress}
          onImageDelete={removeImage}
          onImagesUpload={upload}
          onImagesOrderUpdate={updateImagesOrder}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default Location;
