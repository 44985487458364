import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const Dropdown = ({ text, options, color = 'inherit' }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        color={color}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        endIcon={<ExpandMoreIcon />}
      >
        {text}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {options.map(({ name, to, onClick = () => {} }, index) => {
          const linkProps = to ? { component: Link, to } : {};
          return (
            <MenuItem
              {...linkProps}
              onClick={() => {
                onClick();
                handleClose();
              }}
              key={index}
            >
              {name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default Dropdown;
