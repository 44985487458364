import { TextField } from '@mui/material';
import { useTheme } from '@mui/styles';
import AutoSave from 'components/form/AutoSave';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

const Form = ({ location, onSubmit }) => {
  const theme = useTheme();

  const methods = useForm({
    defaultValues: {
      title: location.title ?? '',
      info: location.info ?? '',
      matterportLink: location.matterportLink ?? '',
      mapLink: location.mapLink ?? ''
    }
  });

  const { control, handleSubmit, reset } = methods;

  useEffect(() => {
    reset({
      title: location.title ?? '',
      info: location.info ?? '',
      matterportLink: location.matterportLink ?? '',
      mapLink: location.mapLink ?? ''
    });
  }, [location, reset]);

  return (
    <FormProvider {...methods}>
      <AutoSave onSubmit={onSubmit} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={'title'}
          control={control}
          rules={{ required: 'Title is required' }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              required
              variant="standard"
              fullWidth
              placeholder="Location title"
              InputProps={{
                style: {
                  fontSize: theme.typography.h5.fontSize
                }
              }}
            />
          )}
        />
        <Controller
          name={'info'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant="filled"
              margin="normal"
              placeholder="Info"
              fullWidth
              multiline
              minRows={2}
            />
          )}
        />
        <Controller
          name={'matterportLink'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant="filled"
              margin="normal"
              placeholder="Matterport link, e.g. https://my.matterport.com/show/?m=9FP6w3r1rzX"
              fullWidth
            />
          )}
        />
        <Controller
          name={'mapLink'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              onChange={onChange}
              variant="filled"
              margin="normal"
              placeholder="Google Maps link, e.g. https://goo.gl/maps/GMpkF38zctQiQmAw5"
              fullWidth
            />
          )}
        />
      </form>
    </FormProvider>
  );
};

export default Form;
