import AddIcon from '@mui/icons-material/Add';
import { Button, Paper } from '@mui/material';
import ImagesList from 'components/ImagesList';
import DialogUpload from 'components/upload/DialogUpload';
import React, { useState } from 'react';

const ImagesContainer = ({
  images,
  uploadProgress,
  onImagesUpload,
  onImageDelete,
  onImagesOrderUpdate
}) => {
  const [isUploadOpen, setUploadOpen] = useState(false);

  return (
    <Paper
      sx={{
        my: 4,
        p: 4
      }}
    >
      {images.length && (
        <ImagesList
          images={images}
          onDelete={onImageDelete}
          onImagesOrderUpdate={onImagesOrderUpdate}
        />
      )}
      <DialogUpload
        title="Add images"
        isOpen={isUploadOpen}
        uploadProgress={uploadProgress}
        onClose={() => setUploadOpen(false)}
        onSubmit={async (files) => {
          await onImagesUpload(files);
          setUploadOpen(false);
        }}
        isRequired={true}
        limit={10}
      />
      <Button
        color="secondary"
        aria-label="add"
        onClick={() => setUploadOpen(true)}
      >
        <AddIcon /> Add images
      </Button>
    </Paper>
  );
};

export default ImagesContainer;
