import { combineReducers } from '@reduxjs/toolkit';
import notificationsReducer from 'features/snackbar/store/slice';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  notifications: notificationsReducer
});

export default rootReducer;
