import { ChevronRight } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell from 'components/table/TableCell';
import { orderBy } from 'lodash';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export const CollapsibleRow = ({
  className,
  data = [],
  child,
  link,
  sortColumnIndex,
  sortDirection,
  disableSort = false,
  level = 0
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [sortColumnIndex, sortDirection]);

  const StyledRow = styled(TableRow)({
    background: `rgb(0,0,0, .${level++})`
  });

  const renderChild = (child) =>
    orderBy(child, (row) => row.data[sortColumnIndex].value, [
      sortDirection
    ]).map((row, index) => (
      <CollapsibleRow
        {...row}
        level={level}
        key={index}
        sortColumnIndex={sortColumnIndex}
        sortDirection={sortDirection}
        disableSort={disableSort}
      />
    ));

  return (
    <>
      <StyledRow className={className}>
        {child?.length ? (
          <TableCell>
            <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell />
        )}
        {data.map(({ value, formattedValue }, cellIndex) => (
          <TableCell key={cellIndex}>{formattedValue ?? value}</TableCell>
        ))}
        <TableCell padding="none">
          {link ? (
            <IconButton size="small" component={Link} to={link}>
              <ChevronRight color="primary" />
            </IconButton>
          ) : null}
        </TableCell>
      </StyledRow>

      {child && isOpen ? renderChild(child) : null}
    </>
  );
};

export default CollapsibleRow;
