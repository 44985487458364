const white = '#fff';
const lightGrey = '#ebebeb';
const grey = '#7d7a7a';
const darkGrey = '#272727';
const black = '#101010';
const blue = '#0f2b6b';
const orange = '#FF9B65';

const primary = blue;
const secondary = orange;

const backgroundColor = 'rgb(232,232,232)';
const darkBackgroundColor = 'rgb(189,187,187)';
const headingColor = '#444343';

export {
  primary,
  secondary,
  white,
  lightGrey,
  grey,
  darkGrey,
  orange,
  blue,
  black,
  backgroundColor,
  darkBackgroundColor,
  headingColor
};
