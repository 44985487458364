export const showcaseCollectionName = 'showcases';
export const groupsCollectionName = 'groups';
export const locationsCollectionName = 'locations';
export const commentsCollectionName = 'comments';
export const imagesCollectionName = 'images';

export const showcasesStoragePart = 'showcases';
export const locationsStoragePart = 'locations';
export const imagesStoragePart = 'images';
export const thumbnailsStoragePart = 'thumbnails';

export const smallThumbSubstring = '_400x400';
export const largeThumbSubstring = '_1200x1200';
export const thumbFiletype = 'webp';

export const showcasesPath = () => `${showcaseCollectionName}`;
export const showcasePath = (id) => `${showcasesPath()}/${id}`;

export const groupsPath = (id) => `${showcasePath(id)}/${groupsCollectionName}`;
export const showcaseGroupPath = (showcaseId, groupId) =>
  `${groupsPath(showcaseId)}/${groupId}`;

export const locationsPath = (showcaseId, groupId) =>
  `${showcaseGroupPath(showcaseId, groupId)}/${locationsCollectionName}`;
export const showcaseGroupLocationPath = (showcaseId, groupId, id) =>
  `${locationsPath(showcaseId, groupId)}/${id}`;

export const locationImagesPath = (showcaseId, groupId, locationId) =>
  `${showcaseGroupLocationPath(
    showcaseId,
    groupId,
    locationId
  )}/${imagesCollectionName}`;

export const locationImagePath = (showcaseId, groupId, locationId, id) =>
  `${locationImagesPath(showcaseId, groupId, locationId)}/${id}`;

export const locationCommentsPath = (showcaseId, groupId, locationId) =>
  `${showcaseGroupLocationPath(
    showcaseId,
    groupId,
    locationId
  )}/${commentsCollectionName}`;

export const locationCommentPath = (showcaseId, groupId, locationId, id) =>
  `${locationCommentsPath(showcaseId, groupId, locationId)}/${id}`;

// STORAGE

export const toSmallThumbFileName = (fileName) => {
  const index = fileName.lastIndexOf('.');
  const prefix = fileName.substring(0, index);
  return `${prefix}${smallThumbSubstring}.${thumbFiletype}`;
};

export const toLargeThumbFileName = (fileName) => {
  const index = fileName.lastIndexOf('.');
  const prefix = fileName.substring(0, index);
  return `${prefix}${largeThumbSubstring}.${thumbFiletype}`;
};

export const showcaseLogoStoragePath = (showcaseId, fileName) =>
  `${showcasesStoragePart}/${showcaseId}/${imagesStoragePart}/${fileName}`;

export const showcaseGroupLocationImageStoragePath = (locationId, fileName) =>
  `${locationsStoragePart}/${locationId}/${imagesStoragePart}/${fileName}`;

export const showcaseGroupLocationSmallThumbnailStoragePath = (
  locationId,
  fileName
) =>
  `${locationsStoragePart}/${locationId}/${imagesStoragePart}/${thumbnailsStoragePart}/${toSmallThumbFileName(
    fileName
  )}`;

export const showcaseGroupLocationLargeThumbnailStoragePath = (
  locationId,
  fileName
) =>
  `${locationsStoragePart}/${locationId}/${imagesStoragePart}/${thumbnailsStoragePart}/${toLargeThumbFileName(
    fileName
  )}`;
