import { isRejected } from '@reduxjs/toolkit';
import { showSnackbar } from 'features/snackbar/store/slice';

/**
 * Log a warning and show a toast!
 *
 * todo: clean error handling
 */
export const rtkQueryErrorLogger =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejected(action)) {
      console.warn('RTK rejected an async thunk', action.error);
      dispatch(
        showSnackbar({
          message: `Operation failed - ${action.error.data?.message}`,
          variant: 'error'
        })
      );
    }

    return next(action);
  };
