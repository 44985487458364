import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MovieIcon from '@mui/icons-material/Movie';
import { Box, IconButton, Switch, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import DialogConfirm from 'components/DialogConfirm';
import PageContainer from 'components/PageContainer';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { queryGroups, queryShowcase } from 'shared/queries';
import {
  getShowcase,
  getShowcaseGroups,
  getShowcaseGroupsLoading,
  getShowcaseLoading
} from 'shared/selectors';

import Group from '../../components/Group';
import { createEntityOrder } from '../../components/Ordering';
import LogoUpload from '../../components/upload/ImageUpload';
import useFirebase from '../../services/useFirebase';
import Form from './components/Form';

const Showcase = () => {
  const { showcaseId } = useParams();
  const navigate = useNavigate();
  const {
    addGroup,
    deleteShowcase,
    updateShowcase,
    updateGroup,
    uploadLogo: uploadLogoStorage,
    deleteLogo: deleteLogoStorage
  } = useFirebase();

  useFirestoreConnect([queryShowcase(showcaseId)]);
  useFirestoreConnect([queryGroups(showcaseId)]);

  const showcase = useSelector(getShowcase(showcaseId));
  const groups = useSelector(getShowcaseGroups(showcaseId));
  const showcaseLoading = useSelector(getShowcaseLoading(showcaseId));
  const groupsLoading = useSelector(getShowcaseGroupsLoading(showcaseId));

  const isLoading = showcaseLoading || groupsLoading;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const orderedGroups = useMemo(() => createEntityOrder(groups), [groups]);

  const move = async (index, steps) => {
    const copy = [...orderedGroups];
    const groupToMove = copy[index];
    copy.splice(index, 1);
    copy.splice(index + steps, 0, groupToMove);

    const promises = copy.map((group, index) =>
      updateGroup(showcaseId, group.id, { order: index })
    );

    await Promise.all(promises);
  };

  const onSubmit = async (values) =>
    await updateShowcase(showcaseId, {
      title: values.title,
      subtitle: values.subtitle,
      info: values.info
    });

  const setSharing = async (sharing) =>
    await updateShowcase(showcaseId, {
      sharing
    });

  const removeShowcase = async () => {
    await deleteShowcase(showcaseId);
    navigate('/showcases');
  };

  const addShowcaseGroup = async () => {
    await addGroup(showcaseId);
  };

  const uploadLogo = async (file) => {
    const logoUrl = await uploadLogoStorage(showcaseId, file);
    await updateShowcase(showcaseId, {
      logoUrl
    });
  };

  const deleteLogo = () => {
    deleteLogoStorage(showcase.logoUrl);
    updateShowcase(showcaseId, {
      logoUrl: null
    });
  };

  return (
    <PageContainer
      isLoading={isLoading}
      actions={
        <>
          {showcase && (
            <IconButton onClick={() => setDeleteModalOpen(true)}>
              <DeleteIcon />
            </IconButton>
          )}
          {showcase && (
            <Tooltip
              title={
                showcase?.sharing
                  ? 'Showcase can be viewed by anyone with access to the address'
                  : 'Showcase can NOT be view by others'
              }
            >
              <IconButton
                href={`/view/${showcaseId}`}
                color={showcase?.sharing ? 'success' : 'warning'}
              >
                <MovieIcon />
              </IconButton>
            </Tooltip>
          )}
          {showcase && (
            <Tooltip
              title={
                showcase?.sharing
                  ? 'Switch off to hide showcase from others'
                  : 'Switch on to let the showcase be shared'
              }
            >
              <Switch
                checked={showcase?.sharing}
                onChange={(event) => setSharing(event.target.checked)}
              />
            </Tooltip>
          )}
        </>
      }
    >
      <Box>
        <DialogConfirm
          title={`Delete showcase ${showcase?.title}?`}
          content="All groups and locations will also be deleted"
          buttonText={'Delete'}
          isOpen={deleteModalOpen}
          onCancel={() => setDeleteModalOpen(false)}
          onConfirm={async () => {
            setDeleteModalOpen(false);
            await removeShowcase();
          }}
        />
        {!!showcase && (
          <LogoUpload
            logoUrl={showcase.logoUrl}
            onUpload={uploadLogo}
            onDelete={deleteLogo}
            limit={1}
          />
        )}
        {!!showcase && <Form showcase={showcase} onSubmit={onSubmit} />}
      </Box>
      <Box>
        {orderedGroups.map((group, index) => (
          <Group
            key={`group-${index}`}
            showcaseId={showcaseId}
            group={group}
            visibleUp={index > 0}
            visibleDown={index < orderedGroups.length - 1}
            onClickUp={async (e) => {
              e.stopPropagation();
              await move(index, -1);
            }}
            onClickDown={async (e) => {
              e.stopPropagation();
              await move(index, 1);
            }}
          />
        ))}
      </Box>
      <Box display="flex" justifyContent="center">
        <Button color="secondary" aria-label="add" onClick={addShowcaseGroup}>
          <AddIcon /> Add group
        </Button>
      </Box>
    </PageContainer>
  );
};

export default Showcase;
